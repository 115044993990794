//import { DisplayLabel } from './components/DisplayLabel';

import 'bootstrap';
// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

//import './dark-mode-switch.js';

//https://swiperjs.com/get-started
//import Swiper from 'swiper';
  // import Swiper styles
  //import 'swiper/css';

  //const swiper = new Swiper(...);

//import Masonry from 'masonry-layout';


//npm install flickity-imagesloadedimport PhotoSwipeLightbox from 'photoswipe/lightbox';

//https://www.lightgalleryjs.com/
// import lightGallery from 'lightgallery';

// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"


//var msnry = new Masonry( '.grid', {});

// let Main = {
//   init: async function () {

//     // initialize demo javascript component - async/await invokes some 
//     //  level of babel transformation
//     const displayLabel = new DisplayLabel();
//     await displayLabel.init();

//   }
// };




// Immediately-Invoked Function Expression (IIFE) to avoid polluting global scope
  (function() {
    // The function that attaches our show/hide logic to the select field
    function attachLogicIfFieldExists() {
      const selectField = document.getElementById('nf-field-395');
      const targetField = document.getElementById('nf-field-396-container');

      // If either element is missing, do nothing
      if (!selectField || !targetField) return false;

      // If we've already attached logic once, skip it
      if (selectField.dataset.logicAttached === "true") return true;
      selectField.dataset.logicAttached = "true";

      // Our show/hide function
      function toggleFieldVisibility() {
        if (selectField.value === 'Anders nl.') {
          targetField.style.display = '';
        } else {
          targetField.style.display = 'none';
        }
      }

      // Run once on load (in case a default value is set)
      toggleFieldVisibility();
      // Run on every change
      selectField.addEventListener('change', toggleFieldVisibility);

      // Return true to signal success
      return true;
    }

  // Create a MutationObserver that looks for changes anywhere in the body
  const observer = new MutationObserver(function(mutations, me) {
    // If we successfully attach logic, we can stop observing
    if (attachLogicIfFieldExists()) {
    me.disconnect();
    }
  });

  // Start observing once DOM is ready
  document.addEventListener('DOMContentLoaded', function() {
    observer.observe(document.body, { childList: true, subtree: true });
  });
})();




var Flickity = require('flickity');
require('flickity-imagesloaded');
require('flickity-fullscreen');

//Main.init();
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function footerFunctions(){
  let firstElementsOfFooter = document.querySelectorAll('#menu-footer-menu > .menu-item > a:not(.prevent)');

  //console.log(window.innerWidth);

  if (firstElementsOfFooter && window.innerWidth <= 1000) {
    for (let href of firstElementsOfFooter.values()) {
      href.addEventListener('click', function (e) {
        //console.log('click');
        href.parentElement.classList.toggle('show');
        e.preventDefault();
      });

    }
  }
  //console.log(firstElementsOfFooter);
}
document.addEventListener("DOMContentLoaded", function () {
  
  
  var body_ = document.querySelector("body");
 
  var st = setTimeout(function () {
    body_.classList.add('page-loaded');
  }, 50);
  

  footerFunctions();

  const kaart = document.getElementById('kaart');
  if (kaart !== null) {
    const txt = document.getElementById('txt');
    const ul = document.getElementById('vestigingen');
    
    const wrapper = document.getElementById('wrapper'); //wrapper lokatie punten
    const gItems = wrapper.getElementsByTagName('g');
    kaart.addEventListener('mouseleave', () => {
      txt.style.display = 'none';
    });
    Array.from(gItems).forEach(g => {
      
      g.addEventListener('mouseover', (event) => {
        clearHighlightClasses();
        if (event.target.parentNode.id && event.target.parentNode.id != '') {
          let classN = capitalizeFirstLetter(event.target.parentNode.id);
          classN = '.' + classN;
          
          
          
          let point = event.target.parentNode;
          let t = ul.querySelector(classN);
          console.log(t.textContent);
          let x = point.getBoundingClientRect().x;
          let y = point.getBoundingClientRect().y;
          //txt.style.display = 'block';

          txt.style.left = (x + 20) + 'px';
          txt.style.top = (y + 10) + 'px';
          txt.textContent = t.textContent;
          t.classList.add('active');
        }
        
      });
      g.addEventListener('mouseleave', (event) => {
        if (event.target.parentNode.id && event.target.parentNode.id != '') {
          let classN = capitalizeFirstLetter(event.target.id);
          
          classN = '.' + classN;
          let t = ul.querySelector(classN);
          t.classList.remove('active');
          //var st = setTimeout(() => { txt.style.display = 'none'; },100);
        }

      });
    });
    


  


    function clearHighlightClasses() {
      const liItems = kaart.getElementsByTagName('g');
      Array.from(liItems).forEach(g => {
        g.classList.remove('active');
      });
    }

  

    // Add event listener to the ul element
    ul.addEventListener('mouseover', (event) => {
      clearHighlightClasses();
      // Check if the clicked element is an li
      if (event.target.tagName === 'LI') {
        // Your event handler logic here
        let className = event.target.classList.value.toLowerCase();

        let item = document.getElementById(className);
        item.classList.add('active');

      
      }
    });
    // Function to clear the 'highlight' class from all li elements
  


  }

});

window.onresize = footerFunctions;

document.addEventListener('mouseover', function (event) {
  //console.log(event.target);
  // If the clicked element doesn't have the right selector, bail
  var closest = event.target.closest('.card');
  var closest_row = event.target.closest('.row');
  if (!closest) return;
  console.log(event);
  closest.classList.add('active');
  closest_row.classList.add('active-row');
  
  //alert('doet ie wel!');
  // Don't follow the link
  //event.preventDefault();

  // Log the clicked element in the console
}, false);

document.addEventListener('mouseout', function (event) {
  //console.log(event.target);
  // If the clicked element doesn't have the right selector, bail
  var closest = event.target.closest('.card');
  var closest_row = event.target.closest('.row');
  if (!closest) return;
  console.log(event);
  closest.classList.remove('active');
  closest_row.classList.remove('active-row');

  //alert('doet ie wel!');
  // Don't follow the link
  //event.preventDefault();

  // Log the clicked element in the console


}, false);


console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  console.log('Looks like we are in production mode');
}
 

document.addEventListener('DOMContentLoaded', function () {
  //alert('wp-block-yoast-faq-block');
  var faqs = document.querySelectorAll('.wp-block-yoast-faq-block .schema-faq-section');
  for (var i = 0; i < faqs.length; i++){
    faqs[i].addEventListener('click', function (e) {
      // console.log('clicked');
      // console.log(e.target);
      e.currentTarget.classList.toggle('fcp-opened');
    });
  }
});